@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/utilities";

// $theme-colors: map-merge(
//   $theme-colors,
//   (
//     "theme": rgb(74, 68, 92),
//     "darksky": rgb(12, 13, 26),
//     "nightmode": #490404,
//     "nightmode-dark": #300303,
//     "nightmode-link": #ec9774,
//     "nightmode-link-focus": #e65f28,
//     "light": #cd7070
//   ),
// );

// $utilities: map-merge(
//   $utilities,
//   (   
//     "color": (
//       property: color,
//       class: text,
//       values: map-merge(
//         $theme-colors,
//         (
//           "white": $white,
//           "black": #000
//         )
//       )
//     ),
//     "background-color": (
//       property: background-color,
//       class: bg,
//       values: map-merge(
//         $theme-colors,
//         (
//           "white": $white,
//           "black": #000
//         )
//       )
//     ),
//   )
// );

@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #11151f;
  color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "Bebas Neue";
}
h1 {
  font-size: 4em;
  font-family: "Bebas Neue";
  line-height: 1em;
}
a {
  color: #11151f;
  text-decoration: none;
}
a:hover { 
  color: #00b8ff
}
#root, body {
  max-width: 100vw !important;
  overflow-x: hidden;
}

// a {
//   color: #ec9774;
//   text-decoration: none;
//   font-weight: 500;
// }
// a:focus {
//   color: #e65f28;
//   text-decoration: none;
//   font-weight: 500;
// }