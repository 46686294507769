.App {
  background-color: #11151f;
  color: #ffffff;
  flex: 1 0 auto;
  padding: 10px;
}

.App-header {
  border-bottom: 1px solid;
}

.App-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.App-link {
  color: #61dafb;
}

.subtitle {
  font-size: 2.4em;
  line-height: .1em;
}
h2.month-name {
  position: absolute;
  color: #00b8ff;
  padding: 0.4em .5em;
}
.app-footer {
  position: fixed;
  bottom: 0px;
  left: 0px;
  background-color: black;
  color: white;
  display: block;
  /* width: 100% !important; */
}
@media (max-width: 760px) {
  .col-sm-12 {
    position: relative;
  }
  .actions {
    position: absolute;
    bottom: 0em;
    padding: 0 1em;
    height: 130px;
  }
  .btn-white {
    padding: .2em .4em 0.1em .4em;
    margin-bottom: .1em;
    display: block;
  }
  .container-sm, .container {
    max-width: 100%;
  }
  .app-footer-link-text {
    display:none;
  }
  .app-footer {
    font-size:1.2em;
  }
}
@media (min-width: 761px) { 
  .actions {
    position: relative;
    top: -8em;
    padding: 0em 1em;
  }
  button.btn.btn-white {
    padding: .5em .7em;
    margin-right: 1em;
  }
}

button.btn.btn-white {
  background-color: #fff;
  font-family: "Bebas Neue";
  color: #000;
  font-size: 2em;
}


.app-footer a {
  /* display: block; */
  color: #fff;
  padding:.5em;
}
.app-footer a:hover {
  color: #00b8ff;
}